(function () {
	'use strict';

	angular
		.module('app')
		.factory('filemakerScript', [
			'filemakerScriptConfig',
			'$rootScope',
			'$timeout',
			'seedcodeCalendar',
			'calendarIO',
			'utilities',
			'csvData',
			filemakerScript,
		]);

	function filemakerScript(
		filemakerScriptConfig,
		$rootScope,
		$timeout,
		seedcodeCalendar,
		calendarIO,
		utilities,
		csvData
	) {
		var eventEditQueue = {};

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			getSchedules: getSchedules,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			mutateEvents: mutateEvents,
			getContacts: getContacts,
			getProjects: getProjects,
			showDetail: showDetail,
		};

		function getConfig() {
			return filemakerScriptConfig.config();
		}

		function getFieldMap() {
			return filemakerScriptConfig.fieldMap();
		}

		function getUnusedMap() {
			return filemakerScriptConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return filemakerScriptConfig.allowHTMLMap();
		}
		function getHiddenFieldMap() {
			return filemakerScriptConfig.hiddenFieldMap();
		}
		function getReadOnlyFieldMap() {
			return filemakerScriptConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		// function getSchedules(callback, sourceTemplate) {
		//   utilities.getFile('sc_sources.txt', applySchedules);
		//   function applySchedules(sourcesCSV) {
		//     var schedules = csvData.csvToSources(sourcesCSV);
		//     var primarySchedulePosition = 0;

		//     //Clean schedule data
		//     for (var i = 0; i < schedules.length; i++) {
		//       calendarIO.cleanSchedule(schedules[i]);
		//       if (schedules[i].editable) {
		//         //hasEditableSource = true;
		//       }

		//       if (schedules[i].isPrimary) {
		//         primarySchedulePosition = i;
		//       }

		//     }
		//     if (i === 0) {
		//       schedules[primarySchedulePosition].isPrimary = true;
		//     }

		//     callback(schedules, sourceTemplate);
		//     //Grab the external settings file and run the apply settings function
		//   }
		// }
		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var schedules = [];
			var schedule;
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					//Apply our schedules
					schedule = setSchedule(sources[i]);
					schedules.push(schedule);
				}
			}
			callback(schedules, sourceTemplate);

			function setSchedule(schedule) {
				//Clean schedule data
				schedule.sourceID = schedule.id;
				schedule.fileDateFormat = config.databaseDateFormat;

				//Set custom action
				for (var property in schedule.customActions) {
					var customAction = schedule.customActions[property];
					processAction(customAction);
				}

				//Set event action
				for (var property in schedule.eventActions) {
					var eventAction = schedule.eventActions[property];
					processAction(eventAction);
				}

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							customFieldMutateInput;
					}
				}

				//Clean schedule data
				calendarIO.cleanSchedule(schedule, sourceTemplate);
				return schedule;
			}

			function processAction(action) {
				var javascriptToken = 'javascript:';
				var fileToken = 'file:';

				if (!action.url) {
					return;
				}

				if (
					action.url.slice(0, javascriptToken.length) ===
					javascriptToken
				) {
					//This is inline javascript
					action.url = action.url.substring(javascriptToken.length);
				} else if (
					action.url.slice(0, fileToken.length) === fileToken
				) {
					//This is an external javascript file
					applyActionFromFile(
						action,
						action.url.substring(fileToken.length)
					);
				} else {
					//This is a filemaker script name
					action.url = createURLString(action.url);
				}
			}

			//Grab the external custom actions file and apply that to the custom action object
			function applyActionFromFile(actionObject, file) {
				utilities.getFile(file, customActionCallback);
				function customActionCallback(result) {
					actionObject.url = result;
				}
			}
		}

		function createURLString(url) {
			var urlString =
				"dbk.scriptURL('script=" +
				encodeURIComponent(url) +
				'&$eventID=' +
				'[[eventID]]' +
				'&$source=' +
				'[[eventSource]]' +
				'&$start=' +
				'[[start]]' +
				'&$end=' +
				'[[end]]' +
				'&$allDay=' +
				'[[allDay]]' +
				"');"; // "&$queryID=" + queryID + "');";

			return urlString;
		}

		function isFunction(url) {
			return (
				(url.indexOf('(') !== -1 && url.indexOf(')') !== -1) ||
				url.indexOf(';') !== -1
			);
		}

		function getEvents(start, end, timezone, callback, schedule, options) {
			var query;
			var request;
			var wasSelected = schedule.status.changed;
			var schedules = seedcodeCalendar.get('schedules');
			var queryDateValue = start.valueOf();
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var view = calendarElement.fullCalendar('getView').name;
			var queryDate = calendarElement.fullCalendar('getDate');

			var queryExecuted;
			var queryExecuting;

			var queryID = new Date().getTime(); //Used to create a unique id for our query

			//Convert dates to filemaker numbers
			var date =
				(moment(queryDate).utcOffset(0, true).valueOf() +
					62135683200000) /
				1000;

			var timeStampStart =
				(moment(start).utcOffset(0, true).subtract(1, 'day').valueOf() +
					62135683200000) /
				1000;

			var timeStampEnd =
				(moment(end).utcOffset(0, true).subtract(1, 'day').valueOf() +
					62135683200000) /
				1000;

			//Record a query date so we don't stack requests when navigating through dates
			options.queryDate = queryDateValue;

			if (wasSelected) {
				schedule.status.changed = false;
				options.sources = [];
				options.queue = 1;
			} else if (!options.queue) {
				options.count = !options.count ? 1 : options.count + 1;
				options.sources = [];
				for (var i = 0; i < schedules.length; i++) {
					if (
						schedules[i].sourceTypeID === schedule.sourceTypeID &&
						schedules[i].status.selected
					) {
						options.queue++;
					}
				}
			}

			options.sources.push(schedule.id);

			options.queue--;

			if (options.queue === 0 || schedule.status.changed) {
				query = true;
			}

			//Activate our event listeners
			var fmGolistener = $rootScope.$on(queryID, function () {
				utilities.getFile('sc_events.txt', eventsReceived);
			});

			var eventReceivedListener = $rootScope.$on(
				'filemakerScriptEventReceived-' + queryDateValue,
				function (e, eventData) {
					processEvents(eventData);
				}
			);

			//Submit request to filemaker - Wrap this in a timeout to enable fast scrolling through dates. We want to cancel if we have already moved on to another date
			$timeout(
				function () {
					if (queryDateValue !== options.queryDate) {
						fmGolistener();
						eventReceivedListener();
						callback('');
						return;
					}

					if (query) {
						if (config.preventFetching) {
							//Make sure we re-enable fetching
							config.preventFetching = false;
							var events =
								seedcodeCalendar.get(
									'initCalendar'
								).eventBackup;
							//Clear our event backup so we don't waste the memory storing these events
							seedcodeCalendar.get('initCalendar').eventBackup =
								undefined;

							eventsReceived(events);
							// utilities.getFile('sc_events.txt', eventsReceived);
						} else {
							if (options.sources.length > 0) {
								request = utilities.scriptURL(
									'script=Query%20Events%20From%20WebViewer&param=' +
										date +
										'&$mode=' +
										view +
										'&$source=' +
										options.sources.join('\\n') +
										'&$dateRangeStart=' +
										timeStampStart +
										'&$dateRangeEnd=' +
										timeStampEnd +
										'&$timezone=' +
										timezone,
									queryID
								);
								utilities.getFileOnLoad(
									queryID,
									'sc_watcher.txt',
									'sc_events.txt',
									eventsReceived
								);
							}
						}
						options.queue = 0;
						options.sources = [];
					}
				},
				Math.min(options.count * 100 - 100, 400)
			);

			//Broadcast that we received events for this request
			function eventsReceived(eventData) {
				$rootScope.$broadcast(
					'filemakerScriptEventReceived-' + queryDateValue,
					eventData
				);
				fmGolistener();
			}

			function processEvents(eventData) {
				var countWas = options.count;
				var eventBackupOutput = [];

				$timeout(function () {
					if (options.count === countWas) {
						options.count = 0;
					}
				}, 400);

				eventReceivedListener();
				queryExecuted = true;

				if (Array.isArray(eventData)) {
					for (var i = 0; i < eventData.length; i++) {
						if (eventData[i].eventSource === schedule.id) {
							if (eventData[i].allDay) {
								eventData[i].end.subtract(1, 'day');
							}
							eventBackupOutput.push(eventData[i]);
						}
					}

					callback(eventBackupOutput);
				} else {
					callback(mutateEvents(eventData, schedule));
				}
			}
		}

		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule
		) {
			var clipboardBackup;
			var source;
			var config = seedcodeCalendar.get('config');
			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var allDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;
			var output = [];
			var outputString;
			// var zoneOffset = event.start.zone() * -1;
			var startMoment;
			var endMoment;
			var currentValue;

			var editObject = {};

			//Apply any edits to the event before submitting
			for (var property in fieldMap) {
				//Store our original event data for comparison later

				currentValue = changes.hasOwnProperty(property)
					? changes[property]
					: event[property];

				//We need to adjust the start and end times so they only have the date and time
				if (property === 'end' || property === 'start') {
					editObject[property] = moment(currentValue);
				}
				//Resources need to be called out so we can remove any stored "none" items and make them empty values
				else if (property === 'resource' || property === 'status') {
					editObject[property] = currentValue.slice(); //Using slice here to clone the array
					//Remove "none" label from resources so we don't write it back to the source
					for (var ii = 0; ii < editObject[property].length; ii++) {
						if (editObject[property][ii] === config.noFilterLabel) {
							editObject[property][ii] = '';
						}
					}
				}
				//We don't need to do this here because we already sub arrays for returns
				// else if (property === "tags") {
				// 	editObject[property] = calendarIO.packageTagsOutput(currentValue);
				// }
				else if (customFields && customFields[property]) {
					editObject[property] =
						typeof currentValue !== 'undefined'
							? calendarIO.customFieldOutputTransform(
									property,
									event[property],
									customFields,
									schedule,
									customFieldMutateOutput
								)
							: '';
				}
				//Handle arrays
				else if (Array.isArray(currentValue)) {
					editObject[property] = currentValue.slice();
				} else {
					editObject[property] = currentValue;
				}
				//End of creating edit object

				//Prepare our output data to be submitted to FileMaker
				if (Array.isArray(editObject[property])) {
					output.push(editObject[property].join('\\n'));
				}
				//We need to adjust the start and end times so they only have the date and time
				else if (property === 'end' && allDay) {
					endMoment = moment(editObject[property])
						.utcOffset(0, true)
						.subtract(2, 'days');
					output.push((endMoment.valueOf() + 62135683200000) / 1000);
					// output.push((moment(currentValue).add(zoneOffset, 'minutes').subtract(2, 'days').valueOf() + 62135683200000) / 1000);
				} else if (property === 'start' || property === 'end') {
					startMoment = moment(editObject[property])
						.utcOffset(0, true)
						.subtract(1, 'day');
					output.push(
						(startMoment.valueOf() + 62135683200000) / 1000
					);
					// output.push((moment(currentValue).add(zoneOffset, 'minutes').subtract(1, 'day').valueOf() + 62135683200000) / 1000);
				}
				//Read only title
				else if (property === 'title') {
					output.push('');
				} else if (property === 'isDefaultResource') {
					//Don't do anything as this doesn't exist in FM
				} else {
					//Replace new lines for hard returns
					if (
						typeof currentValue === 'string' ||
						currentValue instanceof String
					) {
						output.push(editObject[property].replace(/\n/g, '\\n'));
					} else {
						output.push(editObject[property]);
					}
				}
			}

			//Set the output string to be passed via url
			outputString = packageOutput(output, false);

			var queryID = new Date().getTime(); //Used to create a unique id for our query

			//check output length and if clipboard is accessible because we may need to use the clipboard on windows for long packages.
			if (
				outputString.length > 2000 &&
				window.clipboardData &&
				clipboardData.setData
			) {
				//Copy to clipboard
				clipboardBackup = window.clipboardData.getData('Text');
				window.clipboardData.setData(
					'Text',
					packageOutput(output, true)
				);
				utilities.scriptURL(
					'script=Submit%20Event%20From%20WebViewer&$eventData=' +
						packageOutput('clipboard', false) +
						'&$clipboard=true&$renderID=' +
						event._id +
						'&$queryID=' +
						queryID
				);
			} else {
				utilities.scriptURL(
					'script=Submit%20Event%20From%20WebViewer&$eventData=' +
						outputString +
						'&$renderID=' +
						event._id +
						'&$queryID=' +
						queryID
				);
			}
			utilities.getFileOnLoad(
				queryID,
				'sc_watcher.txt',
				'sc_update_event.txt',
				processEdit
			);

			var listener = $rootScope.$on(queryID, function () {
				utilities.getFile('sc_update_event.txt', processEdit);
			});
			function processEdit(eventCSV) {
				//Remove $on listener
				listener();
				resultCallback(eventCSV);
			}

			function resultCallback(result) {
				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				calendarIO.confirmEditCallback(result, {
					event: event,
					schedule: schedule,
					editObject: editObject,
					clipboardBackup: clipboardBackup,
					callback: callback,
				});
			}
		}

		function packageOutput(outputData, forClipboard) {
			//Makes a fully formed package to send to our back end for parsing.

			//Test if our data is an array. We need to transform it to an array if it isn't
			outputData = Array.isArray(outputData) ? outputData : [outputData];

			var packageStart = '!~start~!';
			var packageEnd = '!~end~!';
			var outputSeparator = '!~!';
			var dataPackage =
				forClipboard || window.clipboardData
					? outputData.join(outputSeparator)
					: encodeURIComponent(outputData.join(outputSeparator));
			//We need to substute certain characters on windows
			if (!forClipboard && window.clipboardData) {
				dataPackage = dataPackage
					.split('=')
					.join('~EQUALS~')
					.split('&')
					.join('~AMPERSAND~')
					.split('?')
					.join('~QUESTION~')
					.split('%')
					.join('~PERCENT~');
			}

			var separatorAssignments = forClipboard
				? ''
				: '&$separator=' +
					outputSeparator +
					'&$packageStart=' +
					packageStart +
					'&$packageEnd=' +
					packageEnd;
			return (
				packageStart +
				outputSeparator +
				dataPackage +
				outputSeparator +
				packageEnd +
				separatorAssignments
			);
		}

		function deleteEvent(event, callback) {
			var output = [];
			var fieldMap = event.schedule.fieldMap;

			//Apply any edits to the event before submitting
			for (var property in fieldMap) {
				//We just want the source name for our backend as the source object contains both additioanl attributes as well as the list of events
				if (property === 'source') {
					// if (editEvent.event.newSource) {
					//   output.push(editEvent.event.newSource.name);
					// }
					// else {
					//   output.push(editEvent.event.source.name);
					// }
				} else {
					output.push(event[property]);
				}
			}

			var queryID = new Date().getTime(); //Used to create a unique id for our query

			utilities.scriptURL(
				'script=Submit%20Event%20From%20WebViewer&$eventData=' +
					packageOutput(output) +
					'&$action=delete' +
					'&$queryID=' +
					queryID
			);
			utilities.getFileOnLoad(
				queryID,
				'sc_watcher.txt',
				'sc_update_event.txt',
				processDelete
			);

			var listener = $rootScope.$on(queryID, function () {
				utilities.getFile('sc_update_event.txt', processDelete);
			});

			function processDelete(error) {
				//Remove $on listener
				listener();
				calendarIO.confirmDelete(event, error, callback);
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = item.split('\n');
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return item.slice();
			} else {
				return item;
			}
		}

		function mutateEvents(events, schedule) {
			//Please note that although it is required in most cases the schedule argument isn't passed in every instance
			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var fieldList = [];
			for (var property in fieldMap) {
				fieldList.push(property);
			}

			//Return our event object with mutations applied
			return csvData.csvToObject(events, fieldList, true, mutate);

			function mutate(event) {
				if (schedule && event.eventSource !== schedule.id) {
					return false;
				}
				event.schedule = schedule;

				//convert status to array
				event.status =
					event.status === '' ? [] : event.status.split('\n');

				//convert resource to array
				event.resource =
					event.resource === '' ? [] : event.resource.split('\n');

				//convert contact to array
				event.contactName =
					event.contactName === ''
						? []
						: event.contactName.split('\n');
				event.contactID =
					event.contactID === '' ? [] : event.contactID.split('\n');

				//convert project to array
				event.projectName =
					event.projectName === ''
						? []
						: event.projectName.split('\n');
				event.projectID =
					event.projectID === '' ? [] : event.projectID.split('\n');

				//Fix data before returning result
				var startDateTime = event.start.split('T');
				var endDateTime = event.end.split('T');

				if (!event.end) {
					event.end = event.start;
				} else {
					endDateTime[0] = endDateTime[0]
						? endDateTime[0]
						: startDateTime[0];
					endDateTime[1] = endDateTime[1]
						? endDateTime[1]
						: startDateTime[1];
					event.end = endDateTime[0] + 'T' + endDateTime[1];
				}

				//Check for times that are invalid specifically 24:00:00 if they are make them valid dates again
				if (startDateTime[1] && startDateTime[1].slice(0, 2) === '24') {
					event.start = moment(moment(event.start).toDate());
				}

				if (endDateTime[1] && endDateTime[1].slice(0, 2) === '24') {
					event.end = moment(moment(event.end).toDate());
				}

				event.allDay = event.allDay === 'true' ? true : false;

				//return mulated event
				return calendarIO.cleanEvent(event);
			}
		}
		function getContacts(
			callback,
			object,
			searchField,
			displayField,
			criteria
		) {
			//Convert type to source number because we use source numbers on our backend
			var sourceNumber = 1;
			var fileName = 'sc_relatedData' + sourceNumber + '.txt';
			var queryID = new Date().getTime(); //Used to create a unique id for our query
			var request = utilities.scriptURL(
				'script=' +
					encodeURIComponent('List Search From WebViewer') +
					'&$sourceNumber=' +
					sourceNumber +
					'&$searchCriteria=' +
					criteria +
					'&$queryID=' +
					queryID,
				queryID
			);
			utilities.getFileOnLoad(
				queryID,
				'sc_watcher.txt',
				fileName,
				processData
			);

			var listener = $rootScope.$on(queryID, function () {
				utilities.getFile(fileName, processData);
			});

			function processData(result) {
				listener();
				if (callback) {
					callback(csvData.csvToDataList(result));
				}
			}
		}

		function getProjects(
			callback,
			object,
			searchField,
			displayField,
			criteria
		) {
			//Convert type to source number because we use source numbers on our backend
			var sourceNumber = 2;
			var fileName = 'sc_relatedData' + sourceNumber + '.txt';
			var queryID = new Date().getTime(); //Used to create a unique id for our query
			var request = utilities.scriptURL(
				'script=' +
					encodeURIComponent('List Search From WebViewer') +
					'&$sourceNumber=' +
					sourceNumber +
					'&$searchCriteria=' +
					criteria +
					'&$queryID=' +
					queryID,
				queryID
			);
			utilities.getFileOnLoad(
				queryID,
				'sc_watcher.txt',
				fileName,
				processData
			);

			var listener = $rootScope.$on(queryID, function () {
				utilities.getFile(fileName, processData);
			});

			function processData(result) {
				listener();
				if (callback) {
					callback(csvData.csvToDataList(result));
				}
			}
		}

		function showDetail(id, editEvent, type) {
			//check if we've modified this event and save it if we have
			var modifiedEvent = !editEvent.event
				? false
				: calendarIO.eventChanged(editEvent, editEvent.event);
			if (modifiedEvent) {
				calendarIO.getEventChanges(
					editEvent.event,
					editEvent,
					null,
					navigate,
					{isCustomAction: true, endShifted: false}
				);
			} else {
				navigate(editEvent.event);
			}

			function navigate(event) {
				var relatedSource = type === 'contact' ? 1 : 2;
				//We make sure and update our popover data because saving an event (like we most likely did) adds a day to all day events.
				calendarIO.updateEditEvent(editEvent.event, editEvent);

				var queryID = new Date().getTime(); //Used to create a unique id for our query
				var start = moment(event.start);
				var end = event.allDay
					? moment(event.end).subtract('day', 1)
					: moment(event.end); //adjust end date to be inclusive if it is all day
				utilities.scriptURL(
					'script=' +
						encodeURIComponent(
							'Go To Related Record From WebViewer'
						) +
						'&$id=' +
						id +
						'&$sourceNumber=' +
						relatedSource +
						'&$eventID=' +
						editEvent.event.eventID +
						'&$eventSourceNumber=' +
						editEvent.eventSource +
						'&$queryID=' +
						queryID
				);
			}
		}
	}
})();
